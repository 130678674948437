/* strankovani.css */

/* Styling for the pagination container */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Styling for individual pagination items */
.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 12px;
  cursor: pointer;
  color: #fff;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.page-link:hover {
  background-color: #007bff;
  color: #fff;
}

/* Styling for the active pagination item */
.pagination .page-link.active2 {
  background-color: #007bff !important;
  color: #fff !important;
}

/* Styling for disabled pagination items (if needed) */
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
