@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
/* Label Style */
.label-style {
  font-weight: 500;
  color: #2f855a; /* Tmavě zelená */
  margin-bottom: 4px;
  display: inline-block;
}

/* Input Style */
.input-style {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #cbd5e0; /* Světle šedá */
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.input-style:focus {
  border-color: #2f855a; /* Tmavě zelená */
  box-shadow: 0 0 0 3px rgba(47, 133, 90, 0.3);
}

/* Select Style */
.select-style {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #cbd5e0; /* Světle šedá */
  background-color: white;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.select-style:focus {
  border-color: #2f855a; /* Tmavě zelená */
  box-shadow: 0 0 0 3px rgba(47, 133, 90, 0.3);
}

/* Option Style */
.option-style {
  padding: 8px 12px;
  background-color: white;
  color: #2f855a; /* Tmavě zelená */
}

/* Textarea Style */
.textarea-style {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #cbd5e0; /* Světle šedá */
  outline: none;
  width: 100%;
  height: 150px;
  resize: vertical;
}

.textarea-style:focus {
  border-color: #2f855a; /* Tmavě zelená */
  box-shadow: 0 0 0 3px rgba(47, 133, 90, 0.3);
}

/* Checkbox Style */
.checkbox-style {
  width: 20px;
  height: 20px;
  appearance: none;
  background-color: #cbd5e0; /* Světle šedá */
  border-radius: 4px;
  border: 1px solid #a0aec0; /* Středně šedá */
  cursor: pointer;
  position: relative;
}

.checkbox-style:checked {
  background-color: #2f855a; /* Tmavě zelená */
  border-color: rgb(253, 253, 253); /* Tmavě zelená */
}

.header-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./image/header1.jpg");
  background-size: cover; /* aby obrázek pokryl celou hlavičku */
  background-position: center; /* umístění obrázku */
  min-height: 300px; /* nebo jiná výška podle potřeby */
}

.footer-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./image/header1.jpg");
  background-size: cover; /* aby obrázek pokryl celou hlavičku */
  background-position: center; /* umístění obrázku */
  min-height: 50px; /* nebo jiná výška podle potřeby */
  display: flex;
  justify-content: center;
  align-items: center;
}
